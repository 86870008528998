<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		:class="['xs', 'sm'].includes(windowWidth) ? 'p-2' : 'p-3'"
		class="animated fadeIn">
		<div class="row">
			<div class="col-12 col-md-7 col-lg-8 mb-3">
				<b-card
					no-body
					class="h-100">
					<template v-slot:header>
						<h4 class="mb-0">
							{{ translate('transfers_considerations') }}
						</h4>
					</template>
					<b-card-body class="pb-0">
						<div class="text-center mb-4">
							<img
								:src="require(`@/assets/images/themes/${themeName}/wallets/transfer.png`)"
								align="center">
						</div>
						<b-card-title class="h6">
							{{ translate('transfers_title') }}
						</b-card-title>
						<b-card-text
							class="mb-0"
							v-html="translate('transfers_text')" />
						<b-card-text
							v-if="Object.keys(transferLimits).length">
							<ul>
								<li
									v-if="transferLimits.min !== 0"
									v-html="translate('transfer_minimum_amount', { amount: transferLimits.min })" />
								<li
									v-if="transferLimits.max !== 0"
									v-html="translate('transfer_maximum_amount', { amount: transferLimits.max })" />
							</ul>
						</b-card-text>
					</b-card-body>
				</b-card>
			</div>
			<div
				:class="{ 'mb-3': !['xs', 'sm'].includes(windowWidth) }"
				class="col-12 col-md">
				<b-card
					no-body
					class="h-100">
					<template v-slot:header>
						<h4 class="mb-0">
							{{ translate('transfer') }}
						</h4>
					</template>
					<b-card-body class="pb-0">
						<b-card-text>
							{{ translate('transfer_form_text') }}
						</b-card-text>
						<div
							:class="['xs', 'sm'].includes(windowWidth) ? 'py-2' : 'p-2'"
							class="row">
							<div class="col-6 pl-4">
								{{ translate('balance_available') }}
							</div>
							<div class="col-6 font-weight-bold text-right">
								{{ transferType === 'restricted' ? totalBalance['commission'] : balance['commission'] }}
							</div>
						</div>
						<div
							:class="['xs', 'sm'].includes(windowWidth) ? 'py-2' : 'p-2'"
							class="row">
							<form
								class="form-group col-12 mb-0"
								@change="clearError($event.target.name)"
								@keydown="clearError($event.target.name)">
								<div
									id="transferType"
									class="form-group row"
									:errors="errors['transfer_type']">
									<div class="col-6">
										<label
											for="transfer_type"
											class="label pl-2">
											{{ translate('transfer_type') }}
											<span class="text-danger">* </span>
											<span
												v-b-tooltip.hover.html
												:title="translate('transfer_type_tooltip')">
												<i class="fas fa-info-circle" />
											</span>
										</label>
									</div>
									<div class="col-6">
										<select
											id="transfer_type"
											v-model="transferType"
											:class="{ 'is-invalid': hasError('transfer_type') }"
											name="transfer_type"
											class="form-control">
											<option
												v-for="type in transferTypes"
												:key="type"
												:value="type">
												{{ translate(type) }}
											</option>
										</select>
										<template v-if="hasError('transfer_type')">
											<p
												v-for="error in errors['transfer_type']"
												:key="error"
												class="custom-invalid-feedback animated fadeIn pl-2 mb-0 text-right"
												v-text="error" />
										</template>
									</div>
								</div>
								<div
									id="userIdInput"
									class="form-group row"
									:errors="errors['user_id']">
									<div class="col-6">
										<label
											for="amount"
											class="label pl-2">
											{{ translate('transfer_to') }}
											<span class="text-danger">* </span>
											<span
												v-b-tooltip.hover.html
												:title="translate('transfer_to_tooltip')">
												<i class="fas fa-info-circle" />
											</span>
										</label>
									</div>
									<div
										:class="hasError('username') ? 'mb-3' : ''"
										class="col-6">
										<b-input
											v-model="username"
											:placeholder="translate('transfer_to_placeholder')" />
										<template v-if="hasError('username')">
											<p
												v-for="error in errors['username']"
												:key="error"
												class="custom-invalid-feedback animated fadeIn pl-2 mb-0 text-right"
												v-text="error" />
										</template>
									</div>
								</div>
								<div
									id="amountInput"
									class="form-group row"
									:errors="errors['amount']">
									<div class="col-6">
										<label
											for="amount"
											class="label pl-2">
											{{ translate('amount') }}
											<span class="text-danger">*</span>
										</label>
									</div>
									<div class="col-6">
										<input
											id="amount"
											v-model="amount"
											name="amount"
											type="number"
											:class="hasError('amount') ? 'is-invalid' : ''"
											class="form-control form-control-sm text-right"
											aria-describedby="amount"
											placeholder="$0.00">
									</div>
									<div class="col-12">
										<template v-if="hasError('amount')">
											<p
												v-for="error in errors['amount']"
												:key="error"
												class="custom-invalid-feedback animated fadeIn pl-2 mb-0 text-right"
												v-text="error" />
										</template>
									</div>
								</div>
								<div
									id="passwordInput"
									class="form-group row mb-0"
									:errors="errors['transaction_password']">
									<div class="col-6">
										<label
											for="transaction_password"
											class="label pl-2">
											{{ translate('wallet_password') }}
											<span class="text-danger">*</span>
											<b-button
												v-b-modal="'wallet-password-modal'"
												tabindex="-1"
												variant="link"
												class="p-0 ml-2 align-bottom text-dark">
												<i class="fas fa-question-circle" />
											</b-button>
										</label>
									</div>
									<div class="col-6 align-self-center">
										<input-password
											id="transaction_password"
											v-model="password"
											name="transaction_password"
											:placeholder="translate('password')"
											:input-class="`${hasError('transaction_password') ? 'is-invalid' : ''} form-control form-control-sm text-left`"
											toggle-class="form-control form-control-sm" />
									</div>
									<div class="col-12">
										<template v-if="hasError('transaction_password')">
											<p
												v-for="error in errors['transaction_password']"
												:key="error"
												class="custom-invalid-feedback animated fadeIn pl-2 mb-0 text-right"
												v-text="error" />
										</template>
									</div>
								</div>
							</form>
						</div>
						<div class="row p-3 d-flex">
							<b-button
								:disabled="loading || !valid"
								:class="(loading || !valid) ? '' : 'media-btn'"
								type="submit"
								variant="primary"
								size="md"
								block
								class="btn bg-primary-alt"
								@click="verifyTransfer">
								<i
									v-if="loading"
									class="fas fa-spinner fa-spin mr-2" />
								{{ translate('transfer') }}
							</b-button>
						</div>
					</b-card-body>
				</b-card>
			</div>
			<b-modal
				id="wallet-password-modal"
				:title="translate('wallet_password')"
				ok-only>
				{{ translate('wallet_password_modal', { companyName }) }}
			</b-modal>
		</div>
	</div>
</template>
<script>
import InputPassword from '@/components/InputPassword';
import WindowSizes from '@/mixins/WindowSizes';
import { DEFAULT_COUNTRY_CURRENCY } from '@/settings/Country';
import { TRANSFER_TYPES, RESTRICTED_CURRENCY } from '@/settings/Wallets';
import { Wallets as WalletMessages } from '@/translations';
import Alert from '@/util/Alert';
import Wallets from '@/util/Wallets';

export default {
	name: 'WalletsTransfer',
	messages: [WalletMessages],
	components: { InputPassword },
	mixins: [WindowSizes],
	data() {
		return {
			summary: new Wallets(),
			transfer: new Wallets(),
			alert: new Alert(),
			amount: '',
			password: '',
			companyName: process.env.VUE_APP_TITLE,
			themeName: process.env.VUE_APP_THEME,
			username: '',
			defaultCurrency: DEFAULT_COUNTRY_CURRENCY,
			transferType: 'restricted',
			transferTypes: TRANSFER_TYPES,
			restrictedCurrency: RESTRICTED_CURRENCY,
		};
	},
	computed: {
		balance() {
			try {
				const { balance: totalBalance } = this.summary.data.response.data.response;
				return totalBalance;
			} catch (error) {
				return [];
			}
		},
		totalBalance() {
			try {
				const { total_balance: totalBalance } = this.summary.data.response.data.response;
				return totalBalance;
			} catch (error) {
				return [];
			}
		},
		transferLimits() {
			try {
				const { transfer_limits: transferLimits } = this.summary.data.response.data.response;
				return transferLimits;
			} catch (error) {
				return [];
			}
		},
		errors() {
			return this.transfer.errors.errors;
		},
		loading() {
			return !!this.transfer.data.loading;
		},
		valid() {
			return !!this.username.length && !!this.amount.length && !!this.password.length;
		},
	},
	created() {
		this.summary.getBalance();
	},
	methods: {
		hasError(field) {
			if (typeof this.transfer.errors.errors[field] !== 'undefined') {
				return true;
			}
			return false;
		},
		clearError(field) {
			if (field) {
				delete this.transfer.errors.errors[field];
				this.transfer.errors.errors = { ...this.errors };
			}
		},
		verifyTransfer() {
			let currency = this.defaultCurrency;
			if (this.transferType === 'restricted') {
				currency = this.restrictedCurrency;
			}
			const trans = {
				title: this.translate('transfer'),
				text: this.translate('transfer_text', { money: `${this.amount} ${currency}`, user: this.username }),
			};
			const options = {
				confirmButtonText: this.translate('confirm'),
				cancelButtonText: this.translate('cancel'),
			};
			this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
				const payload = {
					transaction_password: this.password,
					amount: this.amount,
					username: this.username,
					transfer_type: this.transferType,
				};
				this.transfer.createWalletTransfer(payload).then(() => {
					this.alert.toast('success', this.translate('transfer_success'));
					this.amount = '';
					this.username = '';
					this.password = '';
					this.transfer.errors.errors = {};
				}).catch(() => {}).finally(() => {
					this.summary.getBalance();
					this.$parent.$parent.$parent.summary.getBalance();
				});
			}).catch(() => {});
		},
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			const hideWalletTransfer = vm.$user.details().hide_wallet_transfer;

			if (hideWalletTransfer) {
				vm.$router.replace({ name: 'Home' });
			}
		});
	},
};
</script>
